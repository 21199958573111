import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import DefaultLayout from "../layout/defaultLayout"
import Img from "gatsby-image"

import "../styles/demo.css"

import axios from "axios"
import HorizontalLine from "../components/HorizontalLine"

const Demo = (props) => {
    const data = useStaticQuery(graphql`
        query {
            computer: file(relativePath: { eq: "computer.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [message, setMessage] = useState("")

    // Set email if given in url query
    useEffect(() => {
        if (props.location) {
            setEmail(new URLSearchParams(props.location.search).get("email"))
        }
    }, [props])

    const handleSubmit = (e) => {
        e.preventDefault()

        const body = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            company_name: companyName,
            message: message,
        }

        axios
            .post("https://api.getparatus.com/api/request-demo-email", body)
            .then(() => {
                navigate("/")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <DefaultLayout title="Request a Demo" noInputBox>
            <HorizontalLine />
            <div className="padding-global">
                <div className=" container-xxlarge vertical-border_light">
                    <div
                        className="flex-container flex-center"
                        style={{ minHeight: "100%", minWidth: "100%" }}
                    >
                        <div
                            style={{
                                minHeight: "calc(100vh - 20rem)",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div style={{ color: "black" }}>
                                <div
                                    className="card-content-row"
                                    style={{ alignItems: "flex-start" }}
                                >
                                    <div className="card-content-item-50 order-2 flex-container flex-center demo-left-column">
                                        <h1
                                            style={{ marginBottom: "5rem" }}
                                            className="title centered full-basis"
                                        >
                                            Redefining readiness
                                            <br />
                                            management
                                        </h1>
                                        <Img
                                            fluid={
                                                data.computer.childImageSharp
                                                    .fluid
                                            }
                                            style={{ width: "70%" }}
                                        />
                                    </div>
                                    <div className="card-content-item-50 order-1 demo-right-column">
                                        <h2
                                            className="subtitle"
                                            style={{ color: "var(--blue)" }}
                                        >
                                            Book your free demo today
                                        </h2>
                                        <form onSubmit={handleSubmit}>
                                            <input
                                                className="demo-input demo-name demo-first-name"
                                                type="text"
                                                required
                                                placeholder="First Name"
                                                value={firstName}
                                                onChange={(e) =>
                                                    setFirstName(e.target.value)
                                                }
                                            />
                                            <input
                                                className="demo-input demo-name"
                                                type="text"
                                                required
                                                placeholder="Last Name"
                                                value={lastName}
                                                onChange={(e) =>
                                                    setLastName(e.target.value)
                                                }
                                            />
                                            <input
                                                className="demo-input"
                                                type="email"
                                                required
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                style={{
                                                    width: "calc(60% + 4rem)",
                                                }}
                                            />
                                            <input
                                                className="demo-input"
                                                type="text"
                                                required
                                                placeholder="Organization"
                                                value={companyName}
                                                onChange={(e) =>
                                                    setCompanyName(
                                                        e.target.value
                                                    )
                                                }
                                                style={{
                                                    width: "calc(60% + 4rem)",
                                                }}
                                            />
                                            <textarea
                                                className="demo-input"
                                                type="text"
                                                required
                                                placeholder="Message"
                                                value={message}
                                                onChange={(e) =>
                                                    setMessage(e.target.value)
                                                }
                                                style={{
                                                    width: "calc(60% + 4rem)",
                                                    resize: "none",
                                                }}
                                                rows={6}
                                            />
                                            <div />
                                            <button
                                                className="default-btn"
                                                type="submit"
                                            >
                                                SUBMIT
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HorizontalLine />
        </DefaultLayout>
    )
}

export default Demo
